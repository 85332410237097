<template>
  <div class="content_box">
    <!-- 顶部:订单数,用户等 -->
    <div class="consumption_data">
      <div class="consumption_item">
        <div class="consumption_item_title">订单数</div>

        <div class="consumption_item_info">
          <div class="item_info_data">
            <div>日新增</div>
            <div>{{ indexDataList.order.day }}</div>
          </div>
          <div class="item_info_data">
            <div>月新增</div>
            <div>{{ indexDataList.order.month }}</div>
          </div>
          <div class="item_info_data">
            <div>总计</div>
            <div>{{ indexDataList.order.total }}</div>
          </div>
        </div>
      </div>

      <div class="consumption_item">
        <div class="consumption_item_title">用户</div>

        <div class="consumption_item_info">
          <div class="item_info_data">
            <div>日新增</div>
            <div>{{ indexDataList.user.day }}</div>
          </div>
          <div class="item_info_data">
            <div>月新增</div>
            <div>{{ indexDataList.user.month }}</div>
          </div>
          <div class="item_info_data">
            <div>总计</div>
            <div>{{ indexDataList.user.total }}</div>
          </div>
        </div>
      </div>

      <div class="consumption_item">
        <div class="consumption_item_title">课程</div>

        <div class="consumption_item_info">
          <div class="item_info_data">
            <div>日新增</div>
            <div>{{ indexDataList.goods.day }}</div>
          </div>
          <div class="item_info_data">
            <div>月新增</div>
            <div>{{ indexDataList.goods.month }}</div>
          </div>
          <div class="item_info_data">
            <div>总计</div>
            <div>{{ indexDataList.goods.total }}</div>
          </div>
        </div>
      </div>

      <div class="consumption_item">
        <div class="consumption_item_title">短信消耗</div>

        <div class="consumption_item_info">
          <div class="item_info_data">
            <div>今日消耗</div>
            <div>{{ indexDataList.sms.day }}</div>
          </div>
          <div class="item_info_data">
            <div>本月消耗</div>
            <div>{{ indexDataList.sms.month }}</div>
          </div>
          <div class="item_info_data">
            <div>总计</div>
            <div>{{ indexDataList.sms.total }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="center_box">
      <!-- 销售额折线图 -->
      <div class="sales_box">
        <!-- 销售折线图标题 -->
        <div class="sales_title">
          <div class="sales_name">销售额折线图</div>
          <div class="sales_right">
            <div class="time_box">
              <div
                class="time_item"
                :class="index == dateIndex ? 'select_time' : ''"
                v-for="(item, index) in ['周', '月', '年']"
                :key="index"
                @click="selectTime(index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <div class="sales_content">
          <div class="sales_data">
            <div class="sales_data_item">
              <div>本周订单</div>
              <div>{{ indexSalesData.order }}</div>
            </div>

            <div class="sales_data_item">
              <div>本周销售额</div>
              <div>{{ indexSalesData.sales }}</div>
            </div>

            <div class="sales_data_item">
              <div>本周客单价</div>
              <div>{{ indexSalesData.unitPrice }}</div>
            </div>
          </div>

          <div id="sales_echart"></div>
        </div>
      </div>

      <div class="center_right">
        <div class="center_right_title">腾讯云总览</div>
        <div class="cneter_right_content">
          <div id="tencent_balance"></div>
          <div class="tencent_consumption_box">
            <div class="tencent_consumption_item">
              <div>本月消耗</div>
              <div>{{ indexTencentData.ThisMonth }}</div>
            </div>

            <div class="tencent_consumption_item">
              <div>上月消耗</div>
              <div>{{ indexTencentData.LastMonth }}</div>
            </div>

            <div class="tencent_consumption_item">
              <div>当前余额</div>
              <div>{{ indexTencentData.balance }}</div>
            </div>
          </div>

          <div id="tencent_statistical"></div>
        </div>
      </div>
    </div>

    <div class="bottom_box">
      <div class="bottom_item">
        <div class="bottom_item_title">总销售额</div>
        <div class="sales_volume">{{ indexTotalSalesData.total_sales }}</div>
        <div class="sales_contrast">
          <div class="contrast_item">
            <span>周同比</span>
            <span>{{ indexTotalSalesData.week_ratio }}%</span>
            <i
              v-if="indexTotalSalesData.week_ratio >= 0"
              class="el-icon-caret-top"
              style="color: #22ac38"
            ></i>
            <i v-else class="el-icon-caret-bottom" style="color: #ff0000"></i>
          </div>
          <div class="contrast_item">
            <span>月环比</span>
            <span>{{ indexTotalSalesData.month_ratio }}%</span>
            <i
              v-if="indexTotalSalesData.month_ratio >= 0"
              class="el-icon-caret-top"
              style="color: #22ac38"
            ></i>
            <i v-else class="el-icon-caret-bottom" style="color: #ff0000"></i>
          </div>
        </div>

        <div class="bottom_box_total">
          <span class="bottom_total_text">日均销售额</span> ¥
          {{ indexTotalSalesData.daily_sales }}
        </div>
      </div>
      <div class="bottom_item">
        <div class="bottom_info_box">
          <div class="bottom_left_box">
            <div class="bottom_item_title">转化率</div>
            <div class="sales_volume">
              {{ indexConversionRateData.conversion_rate }}%
            </div>
          </div>
          <div class="bottom_right_box">
            <el-progress
              type="circle"
              :stroke-width="10"
              :percentage="indexConversionRateData.conversion_rate"
            ></el-progress>
          </div>
        </div>
        <div class="bottom_box_total">
          <span class="bottom_total_text">本周订单</span>
          {{ indexConversionRateData.week_order }}
        </div>
      </div>
      <div class="bottom_item">
        <div class="bottom_item_title">访问量</div>
        <div id="visits_box"></div>

        <div class="bottom_box_total">
          <span class="bottom_total_text">日访问量</span>
          {{ indexVisitsData.daily_visits }}
        </div>
      </div>
      <div class="bottom_item">
        <div class="bottom_item_title">7日留存</div>
        <div id="retained_box"></div>
        <div class="visits_text">{{ indexRetained7thData.retention_num }}</div>
        <div class="bottom_box_total">
          <span class="bottom_total_text">留存率</span
          >{{ indexRetained7thData.retention }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import {
  IndexData,
  IndexSales,
  IndexTencent,
  IndexConversionRate,
  IndexTotalSales,
  IndexRetained7th,
  IndexVisits,
} from "@/api/main";
export default {
  data() {
    return {
      dateIndex: 0, //销售额折线图选择中年月日索引
      indexDataList: {
        order: {},
        user: {},
        goods: {},
        sms: {},
      },
      salesType: "week",
      indexSalesData: {
        xAxis: [],
        order: "",
        sales: "",
        series: [],
        unitPrice: "",
      },

      indexTencentData: {
        LastMonth: "",
        ThisMonth: "",
        balance: "",
      },

      indexConversionRateData: {},
      indexTotalSalesData: {},
      indexRetained7thData: {},
      indexVisitsData: {},
    };
  },
  created() {
    this.getIndexData();
    this.getIndexSales();
    this.getIndexTencent();
    this.getIndexConversionRate();
    this.getIndexTotalSales();
    this.getIndexRetained7th();
    this.getIndexVisits();
  },
  methods: {
    // 首页访问量
    getIndexVisits() {
      IndexVisits().then((res) => {
        this.indexVisitsData = res.data;
        // 访问量图表容器
        let visitsChart = echarts.init(document.getElementById("visits_box"));

        // 访问量图配置信息
        let visitsOption = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "3%",
            containLabel: false,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: res.data.x_data,
              show: false,
            },
          ],
          yAxis: [
            {
              type: "value",
              show: false,
            },
          ],
          series: [
            {
              name: "日语",
              type: "line",
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  [
                    { offset: 1, color: "#fd5990" },
                    { offset: 0, color: "#FFFFFF" },
                  ]
                ),
              },
              data: res.data.y_data[0].data,
              smooth: true,
              symbol: "none",
              itemStyle: {
                normal: {
                  color: "#fd5990",
                  lineStyle: {
                    //  width:3,
                    color: "#fd5990",
                  },
                },
              },
            },
            {
              name: "韩语",
              type: "line",
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  [
                    { offset: 1, color: "#5AA6F9" },
                    { offset: 0, color: "#FFFFFF" },
                  ]
                ),
              },
              data: res.data.y_data[1].data,
              smooth: true,
              symbol: "none",
              itemStyle: {
                normal: {
                  color: "#4F6DFA",
                  lineStyle: {
                    color: "#4F6DFA",
                  },
                },
              },
            },
            {
              name: "法语",
              type: "line",
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  [
                    { offset: 1, color: "#6B5AF4" },
                    { offset: 0, color: "#FFFFFF" },
                  ]
                ),
              },
              data: res.data.y_data[2].data,
              smooth: true,
              symbol: "none",
              itemStyle: {
                normal: {
                  color: "#6B5AF4",
                  lineStyle: {
                    color: "#6B5AF4",
                  },
                },
              },
            },
          ],
        };

        // 访问量图设置配置信息
        visitsChart.setOption(visitsOption);

        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener("resize", function () {
          visitsChart.resize();
          // retainedChart.resize();
        });
      });
    },
    // 首页7日留存
    getIndexRetained7th() {
      IndexRetained7th().then((res) => {
        this.indexRetained7thData = res.data;
        // 留存图表容器
        let retainedChart = echarts.init(
          document.getElementById("retained_box")
        );
        // 留存图配置信息
        let retainedOption = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "3%",
            containLabel: false,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: res.data.x_data,
              show: false,
            },
          ],
          yAxis: [
            {
              type: "value",
              show: false,
            },
          ],
          series: [
            {
              name: "7日留存",
              type: "line",
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  [
                    { offset: 1, color: "#6B5AF4" },
                    { offset: 0, color: "#FFFFFF" },
                  ]
                ),
              },
              data: res.data.y_data,
              symbol: "none",
              itemStyle: {
                normal: {
                  color: "#6B5AF4",
                  lineStyle: {
                    //  width:3,
                    color: "#6B5AF4",
                  },
                },
              },
            },
          ],
        };
        // 留存图设置配置信息
        retainedChart.setOption(retainedOption);
        window.addEventListener("resize", function () {
          retainedChart.resize();
        });
      });
    },
    // 首页总销售额
    getIndexTotalSales() {
      IndexTotalSales().then((res) => {
        this.indexTotalSalesData = res.data;
      });
    },
    // 首页转化率
    getIndexConversionRate() {
      IndexConversionRate().then((res) => {
        this.indexConversionRateData = res.data;
      });
    },
    // 销售额折线图选择年月日
    selectTime(index) {
      if (index == this.dateIndex) return;
      this.dateIndex = index;
      switch (index) {
        case 0:
          this.salesType = "week";
          this.getIndexSales();
          break;
        case 1:
          this.salesType = "month";
          this.getIndexSales();
          break;
        case 2:
          this.salesType = "year";
          this.getIndexSales();
          break;
        default:
          break;
      }
    },

    // 获取首页数据
    getIndexData() {
      IndexData().then((res) => {
        this.indexDataList = res.data;
      });
    },

    // 获取首页销售额折线图
    getIndexSales() {
      IndexSales({ type: this.salesType }).then((res) => {
        this.indexSalesData = res.data;
        let salesChart = echarts.init(document.getElementById("sales_echart"));
        // 销售折线图配置信息
        let salesOption = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: this.indexSalesData.xAxis,
            },
          ],
          yAxis: [
            {
              type: "value",
              show: true,
            },
          ],
          series: [
            {
              name: "销售额",
              type: "line",
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0, //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位. 而0 0 0 1则代表渐变色从正上方开始
                  [
                    { offset: 1, color: "#4069DC" },
                    { offset: 0, color: "#FFFFFF" },
                  ]
                ),
              },
              data: this.indexSalesData.series,
              itemStyle: {
                normal: {
                  color: "#4069DC",
                  lineStyle: {
                    //  width:3,
                    color: "#4069DC",
                  },
                },
              },
            },
          ],
        };

        // 销售折线图设置配置信息
        salesChart.setOption(salesOption);
        window.addEventListener("resize", function () {
          salesChart.resize();
        });
      });
    },

    // 首页腾讯云总览
    getIndexTencent() {
      IndexTencent().then((res) => {
        this.indexTencentData = res.data;
        // 腾讯云统计柱状图
        let tencentStatisticalChart = echarts.init(
          document.getElementById("tencent_statistical")
        );
        // 腾讯云统计饼图
        let tencentStatisticalPerChart = echarts.init(
          document.getElementById("tencent_balance")
        );

        // 腾讯云统计饼图配置信息
        let tencentStatisticalPerOption = {
          tooltip: {
            trigger: "item",
          },
          // legend: {
          //   top: "0%",
          //   left: "center",
          // },

          series: [
            {
              // name: '访问来源',
              type: "pie",
              radius: ["60%", "40%"],
              avoidLabelOverlap: true,
              label: {
                show: false,
                position: "center",
                formatter: "{b}:\n￥{d}",
                color: "#4C8BFD",
                fontSize: "14",
                fontWeight: "bold",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "14",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: res.data.pieChart,
            },
          ],
        };

        // 腾讯云统计柱状图配置信息
        let tencentStatisticalOption = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "0%",
            right: "0%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: "category",
            data: res.data.category,
          },
          series: [
            {
              type: "bar",
              data: res.data.line,
              itemStyle: {
                //通常情况下：
                normal: {
                  color: function (params) {
                    let colorList = [
                      "#dd4ff7",
                      "#61bee6",
                      "#28d7c0",
                      "#6b5af4",
                      "#f7b84f",
                      "#f74f77",
                      "#1CA3FF",
                    ];
                    return colorList[params.dataIndex];
                  },

                  label: {
                    show: true, //开启显示
                    position: "right", //在上方显示
                    textStyle: {
                      //数值样式
                      color: "black",
                      fontSize: 12,
                    },
                  },
                },
              },
              barWidth: 18,
            },
          ],
        };

        // 腾讯云统计饼图设置配置信息
        tencentStatisticalPerChart.setOption(tencentStatisticalPerOption);

        // 腾讯云统计柱状图设置配置信息
        tencentStatisticalChart.setOption(tencentStatisticalOption);
        //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
        window.addEventListener("resize", function () {
          tencentStatisticalChart.resize();
          tencentStatisticalPerChart.resize();
        });
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.content_box {
  width: 100%;
  height: 100%;
}
.consumption_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .consumption_item {
    flex: 1;
    height: 160px;
    background: #1ca3ff;
    box-shadow: 0px 0px 9px 0px rgba(98, 83, 190, 0.44);
    border-radius: 4px;
    padding: 30px;
    padding-top: 19px;
    margin-left: 20px;

    .consumption_item_title {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }

    .consumption_item_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item_info_data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        div {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.8;
          &:nth-child(2) {
            margin-top: 20px;
            font-size: 24px;
            font-family: Arial;
            font-weight: bold;
            opacity: 1;
          }
        }
      }
    }
    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-child(2) {
      background-color: #f74f77;
    }

    &:nth-child(3) {
      background-color: #f7b84f;
    }

    &:nth-child(4) {
      background-color: #6b5af4;
    }
  }
}

.center_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 600px;
  .sales_box {
    width: 75%;
    // width: 1024px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(162, 162, 226, 0.59);
    border-radius: 4px;
    padding: 19px 30px 25px 30px;

    .sales_title {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 13px;
      border-bottom: 1px solid #cccccc;
      .sales_name {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #323232;
      }

      .sales_right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time_box {
          width: 180px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .time_item {
            width: 60px;
            height: 30px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #969696;
          }
          .select_time {
            background: #37a58c;
            color: #ffffff;
          }
        }

        .el-button {
          width: 70px;
          height: 32px;
          background-color: #37a58c;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 160px;
        }
      }
    }

    .sales_content {
      #sales_echart {
        flex: 1;
        height: 460px;
      }
      .sales_data {
        margin-top: 40px;
        width: 100%;
        display: flex;
        padding-left: 30px;
        .sales_data_item {
          margin-right: 114px;
          display: flex;
          align-items: center;
          justify-content: center;
          div {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #323232;
            opacity: 0.8;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            &:nth-child(2) {
              font-size: 30px;
              font-family: Arial;
              font-weight: bold;
              color: #4c8bfd;
              opacity: 1;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .center_right {
    margin-left: 20px;
    flex: 1;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(162, 162, 226, 0.59);
    border-radius: 4px;
    padding: 19px 30px 25px 30px;
    .center_right_title {
      display: flex;
      align-items: center;
      padding-bottom: 13px;
      border-bottom: 1px solid #cccccc;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #323232;
    }
    .cneter_right_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      #tencent_balance {
        width: 180px;
        height: 180px;
      }

      .tencent_consumption_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tencent_consumption_item {
          div {
            &:nth-child(1) {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #323232;
              line-height: 30px;
              opacity: 0.8;
            }
            &:nth-child(2) {
              font-size: 18px;
              font-family: Arial;
              font-weight: bold;
              color: #4c8bfd;
              line-height: 30px;
            }
          }
        }
      }

      #tencent_statistical {
        width: 100%;
        height: 280px;
      }
    }
  }
}

.bottom_box {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 100px;
  .bottom_item {
    flex: 1;
    height: 240px;
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(98, 83, 190, 0.44);
    border-radius: 4px;
    margin-left: 20px;
    padding: 30px;
    padding-bottom: 23px;
    position: relative;
    &:nth-child(1) {
      margin-left: 0px;
    }
    .bottom_item_title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #323232;
      opacity: 0.8;
    }

    .sales_volume {
      margin-top: 20px;
      font-size: 26px;
      font-family: Arial;
      font-weight: bold;
      color: #4c8bfd;
      margin-bottom: 14px;
    }

    .sales_contrast {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .contrast_item {
        padding: 8px 16px;
        display: flex;
        align-items: center;
        background-color: #f2f7f9;
        border-radius: 4px;

        span {
          &:nth-child(1) {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #323232;
            opacity: 0.8;
            white-space: nowrap;
          }
          &:nth-child(2) {
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #4c8bfd;
            margin-left: 11px;
            margin-right: 18px;
          }
        }
      }
    }

    .bottom_box_total {
      margin-top: 19px;
      border-top: 1px solid #cccccc;
      padding-top: 20px;
      .bottom_total_text {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #323232;
        margin-right: 10px;
      }
    }

    .bottom_info_box {
      display: flex;
      justify-content: space-between;
      .bottom_right_box {
        .el-progress {
          width: 130px;
          height: 130px;
        }
      }
    }

    #visits_box {
      width: 100%;
      height: 102px;
    }
    #retained_box {
      width: 100%;
      height: 102px;
    }

    .visits_text {
      position: absolute;
      left: 30px;
      top: 60px;
      font-size: 26px;
      font-family: Arial;
      font-weight: bold;
      color: #4c8bfd;
    }
  }
}
</style>
