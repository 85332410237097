import request from "@/utils/request";

// 首页数据
export function IndexData() {
    return request({
        url: '/admin/base/indexData',
        method: 'get',
    }
    )
}

// 首页销售额折线图
export function IndexSales(params) {
    return request({
        url: '/admin/base/indexSales',
        method: 'get',
        params
    }
    )
}

// 首页腾讯云总览
export function IndexTencent() {
    return request({
        url: '/admin/base/indexTencent',
        method: 'get',
    }
    )
}

// 首页转化率
export function IndexConversionRate() {
    return request({
        url: '/admin/base/indexConversionRate',
        method: 'get',
    }
    )
}

// 首页总销售额
export function IndexTotalSales() {
    return request({
        url: '/admin/base/indexTotalSales',
        method: 'get',
    }
    )
}

// 首页7日留存
export function IndexRetained7th() {
    return request({
        url: '/admin/base/indexRetained7th',
        method: 'get',
    }
    )
}

// 首页访问量
export function IndexVisits() {
    return request({
        url: '/admin/base/indexVisits',
        method: 'get',
    }
    )
}


